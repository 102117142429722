import React from 'react'
import getYouTubeId from 'get-youtube-id'
import BaseYouTube from 'react-youtube'

const YouTube = ({node}) => {
    const {url} = node
    const id = getYouTubeId(url)
    return (
        <div className='mb-8 pb-2/4 relative'>
            <BaseYouTube className='w-full absolute inset-0 h-full' videoId={id}/>
        </div>
    )
}

export default YouTube;