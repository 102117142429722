import React from 'react'
import Figure from './Figure'
import YouTube from "./youtube";
import BlockContent from '@sanity/block-content-to-react'
import Vimeo from "./vimeo";

const linkify = (inputText) => {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="https://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
}

const linkifyBlock = (block) => {
    if (block.hasOwnProperty('children')) {
        block.children = block.children.map(linkifyBlock)
    } else if (block.hasOwnProperty('text')) {
        block.text = linkify(block.text)
    }
    return block
}

const BlockRenderer = props => {
    const {style = 'normal'} = props.node;

    // if (/^h\d/.test(style)) {
    //     const level = style.replace(/[^\d]/g, '')
    //     return React.createElement(style, {className: `heading-${level}`}, props.children)
    // }

    if (style === 'normal') {
        let {children} = props;
        let newChildren = []
        children.forEach((child, index) => {
            if (typeof child === 'string') {
                let email = child.match(/(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim)
                if (email) {
                    let currentEmail = email[0]
                    child = child.split(currentEmail)
                    newChildren.push(child[0])
                    newChildren.push((
                        <a key={`email-${currentEmail}`} href={`mailto:${currentEmail}`}>{currentEmail}</a>))
                    newChildren.push(child[1])
                } else {
                    newChildren.push(child)
                }
            } else {
                newChildren.push(child)
            }
        })
        return <p>{newChildren}</p>
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props)
}

const iframeBlock = ({node}) => {
    return (
        <div className={!node.maxHeight ? 'mb-8 pb-2/4 relative' : 'mb-8'}>
            <iframe
                className={!node.maxHeight ? 'inset-x inset-y absolute' : ' w-full'}
                src={node.url}
                style={{height: node.maxHeight ? node.maxHeight + 'px' : ''}}
            />
        </div>
    )
}

const serializers = {
    types: {
        authorReference: ({node}) => <span></span>,
        mainImage: Figure,
        youtube: YouTube,
        vimeo: Vimeo,
        iframe: iframeBlock,
        block: BlockRenderer
    }
}

export default serializers
