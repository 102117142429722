import React from 'react'
import sanityClient from '../lib/client'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
    return builder.image(source)
}

export default ({node}) => {
    if (!node || !node.asset || !node.asset._id) {
        return null
    }

    let position = node.hasOwnProperty('text_align') && node.text_align ? node.text_align : 'right';

    return (
        <div>

            <div
                className={'flex -mx-4 flex-col ' + (position !== 'top' && position !== 'bottom' ? 'md:flex-row mb-6' : '')}>
                <div
                    className={'flex-1 px-4 ' + (position === 'top' || position === 'left' ? 'order-last' : 'order-first')}>
                    <img src={urlFor(node.asset).width(1440).fit('max').url()} alt={node.alt}/>
                </div>
                {node.caption && (
                    <div className={'flex-1 px-4 ' + (position === 'bottom' ? 'mt-4' : '')}>
                        <p>{node.caption}</p>
                    </div>
                )}
            </div>
        </div>
    )
}
