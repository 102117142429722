import React, {useState} from 'react'
import parseVideoUrl from '../lib/parseVideoUrl'

const Vimeo = React.memo(({node, autoplay = false}) => {
    const videoData = parseVideoUrl(node.url);
    return (
        <div className="aspect-w-16 aspect-h-9">
            <iframe
                src={`https://player.vimeo.com/video/${videoData.id}?title=0&byline=0&portrait=0&autoplay=${autoplay ? '1' : '0'}`}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
    )
});

export default Vimeo;